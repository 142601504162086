import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import RelatedList from '../components/RelatedList'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { 
  FacebookShareButton, 
  FacebookIcon, 
  LineShareButton, 
  LineIcon,
  LinkedinShareButton, 
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  headings,
  featuredimage,
  date,
  slug
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section blog-flex">
      {helmet || ''}
      <div className=" blog-container content">
        <div className="">
          <div className="is-10 is-offset-1">
            <p className="date is-size-5 blog-date card-date is-block">{date}</p>
            <h1 className="title blog-title is-size-3 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            {tags && tags.length ? (
                <ul className="taglist blog-head-tag-list">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link  
                        className="blog-tag"
                        to={`/tags/${kebabCase(tag)}/`}
                      >
                        #{tag}
                      </Link>
                    </li>
                  ))}
                </ul>
            ) : null}
            <p>{description}</p>
            <div className="blog-top-featured-thumbnail blog-thumbnail-bottom">
              <PreviewCompatibleImage
                imageInfo={{
                  image: featuredimage,
                  alt: `featured image thumbnail for post ${title}`,
                }}
                imageStyles={{
                  borderRadius_prop: '0px',
                  width_prop: '100%',
                  height_prop: 'auto'
                }}
              />
            </div>
            <PostContent content={content} />
            <div className="mobile-share-buttons" style={{ marginTop: `4rem` }}>
              <h3>Share</h3>
              <FacebookShareButton url={"https://www.hacknotes.jp/blog/"+slug}>
                <FacebookIcon size={60} round />
              </FacebookShareButton>

              <LineShareButton url={"https://www.hacknotes.jp/blog/"+slug} >
                <LineIcon size={60} round />
              </LineShareButton>

              <LinkedinShareButton url={"https://www.hacknotes.jp/blog/"+slug} >
                <LinkedinIcon title={title} size={60} round />
              </LinkedinShareButton>

              <TwitterShareButton title={title} via="ReleaseNotes7" url={"https://www.hacknotes.jp/blog/"+slug} >
                <TwitterIcon size={60} round />
              </TwitterShareButton>
            </div>
            <div className="share-buttons" style={{ marginTop: `4rem` }}>
              <h3>Share</h3>
              <FacebookShareButton url={"https://www.hacknotes.jp/blog/"+slug}>
                <FacebookIcon size={100} round />
              </FacebookShareButton>

              <LineShareButton url={"https://www.hacknotes.jp/blog/"+slug} >
                <LineIcon size={100} round />
              </LineShareButton>

              <LinkedinShareButton url={"https://www.hacknotes.jp/blog/"+slug} >
                <LinkedinIcon title={title} size={100} round />
              </LinkedinShareButton>

              <TwitterShareButton title={title} via="ReleaseNotes7" url={"https://www.hacknotes.jp/blog/"+slug} >
                <TwitterIcon size={100} round />
              </TwitterShareButton>
            </div>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h3>Tags</h3>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link  
                        className="blog-tag"
                        to={`/tags/${kebabCase(tag)}/`}
                      >
                        #{tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        <div>
        </div>
        <RelatedList title={title} tags={tags} />
      </div>
      <div id="slidebar" className="sidebar">
      <button className="table-of-content-button" data-menu-toggle>×</button>
        <div
          className="table-of-content"
          dangerouslySetInnerHTML={{ __html: headings }}
        />
        
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  featuredimage: PropTypes.object,
  date: PropTypes.string,
  slug: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  const func = function(){
    const toggles = document.querySelectorAll('[data-menu-toggle]');
    const slidebar = document.getElementById('slidebar');

    toggles.forEach(toggle => {
      toggle.addEventListener('click', function(event) {
        event.stopPropagation();
        slidebar.classList.toggle('is-open');
      });
    });
  }
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate="%s | Hack Notes">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        headings={post.tableOfContents}
        featuredimage={post.frontmatter.featuredimage}
        slug={post.frontmatter.slug}
      />
      <button data-menu-toggle className="smartphone-button" onClick={func}>
        ＜
      </button>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
        description
        tags
        slug
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 240, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      tableOfContents(absolute: false
        pathToSlugField: "frontmatter.path"
        maxDepth: 6)
    }
  }
`
