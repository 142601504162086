import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ imageInfo, imageStyles }) => {
  const {borderRadius_prop, width_prop, height_prop}=imageStyles
  const imageStyle = { 
    borderRadius: borderRadius_prop,
    width: width_prop,
    height: height_prop
   }
  const { alt = '', childImageSharp, image } = imageInfo

  if (!!image && !!image.childImageSharp) {
    return (
      <Img className="mobile-thumnail" style={imageStyle} fluid={image.childImageSharp.fluid} alt={alt} />
    )
  }

  if (!!childImageSharp) {
    return <Img style={imageStyle} fluid={childImageSharp.fluid} alt={alt} />
  }

  if (!!image && typeof image === 'string')
    return <img style={imageStyle} src={image} alt={alt} />

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
  imageStyles: PropTypes.shape({
    borderRadius_prop: PropTypes.string,
    width_prop: PropTypes.string,
    height_prop: PropTypes.string,
  }).isRequired,
}

export default PreviewCompatibleImage
