import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Layout from '../components/Layout'

export const IndexPageTemplate = ({
  edges
}) => (
  <div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="column is-12">
                  <div className="columns is-multiline">
                    {edges &&
                      edges.map(({ node: edge }) => (
                        <div className="is-parent column is-4" key={edge.id}>
                          <article
                            className={`blog-list-item tile is-child box notification ${
                              edge.frontmatter.featuredpost ? 'is-featured' : ''
                            }`}
                          >
                            <header>
                              <div className="featured-hover">
                                {edge.frontmatter.featuredimage ? (
                                  <div className="featured-thumbnail">
                                    <Link
                                        to={edge.fields.slug}
                                      >
                                      <PreviewCompatibleImage
                                        imageInfo={{
                                          image: edge.frontmatter.featuredimage,
                                          alt: `featured image thumbnail for post ${edge.frontmatter.title}`,
                                        }}
                                        imageStyles={{
                                          borderRadius_prop: '0px',
                                          width_prop: '100%',
                                          height_prop: '12rem'
                                        }}
                                      />
                                    </Link>
                                  </div>
                                ) : null}
                                <div className="post-meta">
                                    <Link
                                      to={edge.fields.slug}
                                    >
                                      <span className="date is-size-7 card-date is-block">
                                        {edge.frontmatter.date}
                                      </span>
                                      <p className="title has-text-title is-size-6">{edge.frontmatter.title}</p>
                                    </Link>
                                </div>
                              </div>
                              <div className="tags">
                                {edge.frontmatter.tags.map((tag, index) => (
                                  <Link
                                    className="card-tag"
                                    to={`/tags/${kebabCase(tag)}/`}
                                    key={index}
                                    >
                                    #{tag}
                                  </Link>
                                ))}
                              </div>
                            </header>
                          </article>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  edges: PropTypes.array
}

const IndexPage = ({ data, pageContext }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout>
      <IndexPageTemplate
        edges={edges}
      />
      <div className="pagination-section">
        <ul className="pagination">
          {pageContext.isFirst && (
            <li className="prev prev-dummy">
              <Link><span></span></Link>
            </li>
          )}
          {!pageContext.isFirst && (
            <li className="prev">
              <Link 
                className="pagination-button"
                to={
                  pageContext.currentPage === 2
                    ? `/`
                    : `/${pageContext.currentPage - 1}/`
                }
                rel="prev"
                >
                <span>← Newer</span>
              </Link>
            </li>
          )}
          <li className="page-number">{pageContext.currentPage}&nbsp;&nbsp;/&nbsp;&nbsp;{pageContext.blogPages}</li>
          {pageContext.isLast && (
            <li className="next next-dummy">
              <Link><span></span></Link>
            </li>
          )}
          {!pageContext.isLast && (
          <li className="next">
            <Link 
              className="pagination-button"
              to={`/${pageContext.currentPage + 1}/`}
              rel="next"
              >
              <span>Older →</span>
            </Link>
          </li>
          )}
        </ul>
      </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate($skip: Int, $limit: Int) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "YYYY.MM.DD")
            featuredpost
            tags
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 240, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
