import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const RelatedList = ({ title, tags }) => (
  <StaticQuery
    query={graphql`
      query RelatedListQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              excerpt(format: PLAIN, pruneLength: 75, truncate: true)
              frontmatter {
                title
                tags
                date(formatString: "YYYY/MM/DD")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 240, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
        const edges_list = data.allMarkdownRemark.edges
        const relatedPosts = edges_list.filter(edge => {
            if (edge.node.frontmatter.title === title) {
            return false;
            }
            for (let i = 0; i < tags.length; i++) {
            if (edge.node.frontmatter.tags.indexOf(tags[i]) >= 0) {
                return true;
            }
            }
            return false;
        });
        if (!relatedPosts) {
            return null;
        }
        const relatedPost4 = relatedPosts.slice(0, 6);
        const latestPost4 = edges_list.slice(0, 6);

        return (
            <div className="is-multiline">
                <h3>関連記事</h3>
                  <div className="container">
                    <div className="section">
                      <div className="content">
                        <div className="column is-12">
                          <div className="columns is-multiline">
                            {relatedPost4 &&
                                relatedPost4.map(({ node: post }) => (
                                <div className="is-parent column is-4" key={post.id}>
                                    <Link to={post.fields.slug}>
                                    <article
                                        className={`blog-list-item tile is-child box notification `}
                                    >
                                      <div className="flex">
                                        <div className="featured-hover">
                                          <div className="featured-thumbnail">
                                              <PreviewCompatibleImage
                                              imageInfo={{
                                                  image: post.frontmatter.featuredimage,
                                                  alt: `featured image thumbnail for post ${post.title}`
                                              }}
                                              imageStyles={{
                                                borderRadius_prop: '0px',
                                                width_prop: '100%',
                                                height_prop: '12rem'
                                              }}
                                              />
                                          </div>
                                          <p className="post-meta">
                                            <span className="date is-size-7 card-date is-block">
                                              {post.frontmatter.date}
                                            </span>
                                            <span className="has-text-title is-size-6">
                                            {post.frontmatter.title}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </article>
                                    </Link>
                                </div>
                            ))}
                            {relatedPost4.length===0 && 
                              <p>関連記事はありません</p>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3>最新記事</h3>
                  <div className="container">
                    <div className="section">
                      <div className="content">
                        <div className="column is-12">
                          <div className="columns is-multiline">
                          {latestPost4 &&
                              latestPost4.map(({ node: post }) => (
                              <div className="is-parent column is-4" key={post.id}>
                                  <Link to={post.fields.slug}>
                                  <article
                                      className={`blog-list-item tile is-child box notification `}
                                  >
                                    <div className="flex">
                                      <div className="featured-hover">
                                        <div className="featured-thumbnail">
                                            <PreviewCompatibleImage
                                            imageInfo={{
                                                image: post.frontmatter.featuredimage,
                                                alt: `featured image thumbnail for post ${post.title}`
                                            }}
                                            imageStyles={{
                                              borderRadius_prop: '0px',
                                              width_prop: '100%',
                                              height_prop: '12rem'
                                            }}
                                            />
                                        </div>
                                        <p className="post-meta">
                                          <span className="date is-size-7 card-date is-block">
                                            {post.frontmatter.date}
                                          </span>
                                          <span className="title has-text-title is-size-6">
                                            {post.frontmatter.title}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                  </Link>
                              </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        );
    }}
  />
);

export default RelatedList;

RelatedList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};