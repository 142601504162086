import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
// import logo from '../img/logo.svg'
// import facebook from '../img/social/facebook.svg'
// import instagram from '../img/social/instagram.svg'
// import twitter from '../img/social/twitter.svg'
// import vimeo from '../img/social/vimeo.svg'

function Footer() {
    const data = useStaticQuery(
      graphql`
        query {
          allMarkdownRemark {
            group(field: frontmatter___tags) {
              fieldValue
              totalCount
            }
          }
        }
      `
    )
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="footer-tags content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="footer-title">All Tags</div>
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="tags footer-tags">
                {data.allMarkdownRemark.group.map((tag, index) => (
                  <Link
                    className="footer-tag"
                    to={`/tags/${kebabCase(tag.fieldValue)}/`}
                    key={index}
                  >
                  #{tag.fieldValue}
                </Link>
                ))}
              </div>
              {/* <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                  </ul>
                </section>
              </div> */}
              {/* <div className="column is-4 social">
                <a title="facebook" href="https://facebook.com">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div> */}
            </div>
            <div className="footer-title footer-title-second">About</div>
            <p>
              Hack Notes はテクノロジーに関する情報を発信する技術系ブログです。<br/>
              <br/>
              プライバシーポリシーに関しては
              <Link
                  className="privacy-policy"
                  to={`/about/`}
                >
                こちら
              </Link>
              をご覧下さい。
            </p>
          </div>
          <div className="footer-title-second copyright">© 2020 HackNotes</div>
        </div>
      </footer>
    )
  }

export default Footer